<template>
	<v-container class="pbackground-transparent">
		<AdminDefaultSubheader class="mb-10"
			>Owners and Admins</AdminDefaultSubheader
		>
		<p class="mb-10">Organization Owners</p>
		<v-container class="d-flex flex-row ma-0 pa-0">
			<span
				v-for="user in userRoles"
				class="mr-6"
				close
				close-icon="mdi-delete"
				:key="user.user_id"
				@click:close="removeUser(user.user_id)"
				@input="changeName($event, user.user_id)"
			>
				{{ user.email }}
			</span>
		</v-container>
		<p class="mb-10">Organization Users</p>
		<v-container class="d-flex flex-row ma-0 pa-0 mb-8">
			<v-row>
				<v-col>
					<div v-for="user in orgUsers" :key="user.user_id">
						<div class="organization-settings__user--item">
							<span class="mr-3"
								><b>name:</b> {{ user.user.name }}</span
							>
							<span><b>email:</b> {{ user.user.email }}</span>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<p class="mb-10">Organization Admins</p>
		<v-container class="d-flex flex-row ma-0 pa-0">
			<v-chip
				v-for="user in userRoles"
				class="mr-6"
				close
				close-icon="mdi-delete"
				:key="user.user_id"
				@click:close="removeUser(user.user_id)"
				@input="changeName($event, user.user_id)"
			>
				{{ user.name }}
				<input type="text" />
			</v-chip>
		</v-container>
		<v-container class="d-flex flex-row ma-0 pa-0">
			<v-btn
				class="admin-primary-button secondary-contrast-background mr-4"
				:disabled="true"
				@click="addExisting"
				>Add existing</v-btn
			>
			<v-btn
				class="admin-primary-button secondary-contrast-background mr-4"
				@click="addNewUser"
				>Add new user</v-btn
			>
		</v-container>
		<div class="d-flex justify-end mt-2">
			<v-btn
				:disabled="true"
				class="admin-primary-button primary-contrast-background"
				@click="save()"
				>Save Changes</v-btn
			>
		</div>
		<v-alert v-if="message" :type="status">{{ message }}</v-alert>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import AdminDefaultSubheader from "../../../components/AdminDefaultSubheader.vue";
export default {
	name: "OrganizationRoles",
	components: { AdminDefaultSubheader },
	computed: {
		userRoles() {
			return this.$store.state.organization.userRoles;
		}
	},
	data() {
		return {
			value: "",
			message: "",
			status: "",
			orgUsers: null
		};
	},
	methods: {
		changeName(e, userId) {},
		async setIsLoading(val) {
			this.$store.dispatch("setIsLoading", val);
		},
		async getOrganizationUsers() {
			console.log(this.$store.state.organization.organizationId);
			const orgId = this.$store.state.organization.organizationId;
			await this.setIsLoading(true);
			try {
				const response = await get(
					`/organization_users?filters=organization_id,enabled&filter_values=${orgId},1`
				);
				const {
					data: { data }
				} = response;
				this.orgUsers = data;
			} catch (error) {
				console.error(error);
			}
			await this.setIsLoading(false);
		},
		addExisting() {
			console.log("add existing");
		},
		addNewUser() {
			this.$router.push({ name: "NewUser" });
		},
		removeUser(userName) {
			this.$store.dispatch("setSingleValue", {
				key: "userRoles",
				value: this.userRoles.filter(el => el.name === userName)
			});
		},
		async save() {
			const response = await this.$store.dispatch(
				"saveOrganizationSettingsDetails"
			);

			this.message = response.message;
			this.status = response.status;
		}
	},
	created() {
		this.getOrganizationUsers();
	}
};
</script>

<style></style>
